import { render, staticRenderFns } from "./BuyerDocuments.vue?vue&type=template&id=61444fd9&scoped=true&"
import script from "./BuyerDocuments.vue?vue&type=script&lang=js&"
export * from "./BuyerDocuments.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61444fd9",
  null
  
)

export default component.exports