<template>
  <div>
    <a-form
      class="ant-advanced-search-form"
      name="advanced_search"
      @submit="handleSearch"
    >
      <div class="filterFields">
        <a-form-item label="Наименование ЮЛ" class="filterFormItems">
          <a-input
            style="margin-bottom: 5px"
            placeholder="Наименование ЮЛ"
          ></a-input>
        </a-form-item>
        <a-form-item label="ИНН" class="filterFormItems">
          <a-input style="margin-bottom: 5px" placeholder="ИНН"></a-input>
        </a-form-item>
      </div>
      <div class="buttons">
        <a-button type="primary">Фильтр</a-button>
        <a-button>Очистить</a-button>
      </div>
    </a-form>
    <div class="top-buttons">
      <a-button
        @click="onCreateClientClick"
        type="primary"
        icon="user-add"
        class="user-add"
      >
        Создать клиента
      </a-button>
    </div>
    <a-table bordered :columns="columns" :data-source="clients">
      <span slot="operation" class="action" slot-scope="text, record">
        <a-button-group class="action-buttons">
          <a-tooltip placement="topLeft" title="Загрузить">
            <a-button icon="download" />
          </a-tooltip>
          <a-tooltip placement="topLeft" title="Печать">
            <a-button icon="printer" />
          </a-tooltip>
        </a-button-group>

        <a-popconfirm
          title="Уверены, что хотите удалить?"
          ok-text="Удалить"
          cancel-text="Отмена"
          placement="topLeft"
          @confirm="() => onDelete(record.id)"
        >
          <a-button
            type="danger"
            ghost
            shape="circle"
            icon="delete"
            size="small"
          />
        </a-popconfirm>
      </span>
    </a-table>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex"

const columns = [
  {
    title: "Код Поставщика",
    dataIndex: "code",
    key: "code",
    width: 100,
  },
  {
    title: "Наименование пользователя",
    key: "name",
    dataIndex: "name",
    width: 130,
  },
  {
    title: "Наименование ЮЛ",
    dataIndex: "ulName",
    key: "ulName",
    width: 180,
  },
  {
    title: "ИНН",
    dataIndex: "inn",
    key: "inn",
    width: 110,
  },
  {
    title: `Эл. адрес "Заказы"`,
    dataIndex: "mail",
    key: "mail",
    width: 150,
  },
  {
    title: "Оплата",
    dataIndex: "paymentStatus",
    key: "paymentStatus",
    width: 150,
  },
  {
    title: "Бухгалтерские документы",
    dataIndex: "accountantDocuments",
    key: "accountantDocuments",
    width: 150,
  },
  {
    title: "Действия",
    dataIndex: "operation",
    scopedSlots: { customRender: "operation" },
    width: 110,
  },
]

export default {
  name: "BuyerDocumentsList",
  props: {
    dealerName: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      clients: "clients/clients",
    }),
  },
  data() {
    return {
      columns,
      selectedRowKeys: ["2"],
      form: this.$form.createForm(this, { name: "advanced_search" }),
    }
  },
  async mounted() {
    await this.getClients()
    if (this.dealerName && this.columns[0].key !== "dealerName") {
      this.columns.unshift({
        title: "Автоград плюс",
        dataIndex: "dealerName",
        key: "dealerName",
        width: 100,
      })
    }
  },
  methods: {
    ...mapActions({
      getClients: "clients/fetchClients",
    }),
    onDelete(key) {
      console.log("delete", key)
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },
    onCreateClientClick() {
      this.$router.push("/createclient")
    },
    handleSearch(e) {
      e.preventDefault()
      this.form.validateFields((error, values) => {
        if (error) {
          console.log("error", error)
        } else {
          console.log("Received values of form: ", values)
          this.searchDetails(values)
        }
      })
    },
    handleChange(info) {
      const status = info.file.status
      if (status !== "uploading") {
        console.log(info.file, info.fileList)
      }
      if (status === "done") {
        this.$message.success(`${info.file.name} file uploaded successfully.`)
      } else if (status === "error") {
        this.$message.error(`${info.file.name} file upload failed.`)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  margin-bottom: 10px;
  position: relative;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#advanced-search .ant-form {
  max-width: none;
}

#advanced-search .search-result-list {
  margin-top: 16px;
  border: 1px dashed #e9e9e9;
  border-radius: 6px;
  background-color: #fafafa;
  min-height: 200px;
  padding: 20px 40px;
}

.search-result-text {
  margin: 20px 0 10px 0;
}

.action-buttons {
  margin-right: 10px;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 15px;
  > button {
    &:not(:first-child) {
      margin-left: 15px;
    }
  }
}

.top-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0 15px;
}

.filterFields {
  display: flex;
  flex-direction: row;
}

.filterFormItems {
  display: block;
  margin-right: 20px;
}

.selects {
  width: 200px;
}

.user-add {
  margin: 0 5px;
}
</style>
